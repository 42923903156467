import { DownloadingRounded, RestartAlt } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React from "react";

function Filters({ fetchVisaOrders, exportCSV }) {
  const [filters, setFilters] = React.useState({});

  const handleChangeFormDataField = React.useCallback((e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <TextField
          onChange={handleChangeFormDataField} // This is just for testing
          name="full_name"
          value={filters?.full_name}
          id="full_name"
          label="Full name"
          size="medium"
        />
        <TextField
          onChange={handleChangeFormDataField} // This is just for testing
          name="email"
          value={filters?.email}
          id="email"
          label="Email"
          size="medium"
        />
        <TextField
          onChange={handleChangeFormDataField} // This is just for testing
          name="mobile"
          value={filters?.mobile}
          id="mobile"
          label="Mobile"
          size="medium"
        />
        <TextField
          onChange={handleChangeFormDataField} // This is just for testing
          name="visa_id"
          value={filters?.visa_id}
          id="visa_id"
          label="Visa ID"
          size="medium"
        />
        <TextField
          onChange={handleChangeFormDataField} // This is just for testing
          name="status"
          value={filters?.status}
          id="status"
          label="Status"
          size="medium"
        />
        <TextField
          onChange={handleChangeFormDataField} // This is just for testing
          name="booked_by"
          value={filters?.booked_by}
          id="booked_by"
          label="Booked by"
          size="medium"
        />
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment} sx={{ display: "flex" }}>
            <DatePicker
              value={filters?.from || null}
              name="from"
              renderInput={(props) => <TextField {...props} fullWidth />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilters((prev) => ({ ...prev, from: moment(date).format("YYYY-MM-DD") }));
              }}
              label="From"
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment} sx={{ display: "flex" }}>
            <DatePicker
              value={filters?.to || null}
              name="to"
              renderInput={(props) => <TextField {...props} fullWidth />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilters((prev) => ({ ...prev, to: moment(date).format("YYYY-MM-DD") }));
              }}
              label="To"
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          mt: 2,
        }}
      >
        <Button
          size="medium"
          p={4}
          variant="contained"
          sx={{ color: "white !important" }}
          onClick={() => fetchVisaOrders(filters)}
        >
          Apply Filters
        </Button>
        <Button
          size="medium"
          p={4}
          variant="contained"
          sx={{
            color: "white !important",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
          onClick={() => {
            setFilters((prev) => ({
              ...prev,
              full_name: null,
              email: null,
              mobile: null,
              rate_hawk_order_id: null,
              guests: null,
              hotel_name: null,
              city: null,
              status: null,
            }));
            fetchVisaOrders(null);
            // setTimeout(() => {
            // }, 200);
          }}
        >
          <RestartAlt />
          Reset Filters
        </Button>
        <Button
          size="medium"
          p={4}
          variant="contained"
          sx={{
            color: "white !important",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
          onClick={() => {
            exportCSV(`${moment().format("YYYY-MM-DD")}-manual-visa-orders`, filters);
          }}
        >
          <DownloadingRounded />
          Export Excel
        </Button>
      </Box>
    </Box>
  );
}

export default Filters;
