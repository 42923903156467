import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Done } from "@mui/icons-material";
import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import countries from "../../../helpers/countries.json";
import currencies from "../../../helpers/currency.json";
import AdapterMoment from "@date-io/moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import axiosInstance from "helpers/axiosInstance";

const FormFirstSection = ({ formData, handleChangeFormDataField, setFormData }) => {
  return (
    <Box>
      <Typography sx={{ mt: 2, mb: 1 }}>Add Customer</Typography>
      {/* <Typography variant="h5">First Section</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="full_name">
            Full name
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="full_name"
            value={formData?.full_name}
            fullWidth
            id="full_name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="email">
            E-mail
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="email"
            value={formData?.email}
            fullWidth
            id="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="mobile">
            Mobile
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="mobile"
            value={formData?.mobile}
            fullWidth
            id="mobile"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="nationality">
            Nationality
          </label>
          <Autocomplete
            disablePortal
            value={formData?.nationality}
            options={countries}
            fullWidth
            onChange={(e, val) => setFormData((prev) => ({ ...prev, nationality: val?.label }))}
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSecondSection = ({
  formData,
  handleChangeFormDataField,
  setFormData,
  handleChangeFormDataPayment,
}) => {
  return (
    <Box>
      <Typography sx={{ mt: 2, mb: 1 }}>Visa Details</Typography>
      {/* <Typography variant="h5">First Section</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="visa_id">
            Visa ID
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="visa_id"
            value={formData?.visa_id}
            fullWidth
            id="visa_id"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="visa_to">
            Going to
          </label>
          <Autocomplete
            value={formData?.visa_to}
            disablePortal
            options={countries}
            fullWidth
            onChange={(e, val) => setFormData((prev) => ({ ...prev, visa_to: val?.label }))}
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="supplier_price">
            Supplier Price
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="supplier_price"
            value={formData?.supplier_price}
            fullWidth
            type="number"
            id="supplier_price"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="supplier_currency">
            Supplier Currency
          </label>
          <Autocomplete
            value={formData?.supplier_currency}
            disablePortal
            options={currencies}
            fullWidth
            onChange={(e, val) =>
              setFormData((prev) => ({ ...prev, supplier_currency: val?.label }))
            }
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="paid_amount">
            Paid Amount
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="paid_amount"
            value={formData?.paid_amount}
            fullWidth
            type="number"
            id="paid_amount"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="paid_currency">
            Paid Currency
          </label>
          <Autocomplete
            value={formData?.paid_currency}
            disablePortal
            options={currencies}
            fullWidth
            onChange={(e, val) => setFormData((prev) => ({ ...prev, paid_currency: val?.label }))}
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Typography style={{ fontSize: "14px" }} htmlFor="booked_on">
              Booking Date
            </Typography>
            <DatePicker
              value={formData?.booked_on}
              name="booked_on"
              renderInput={(props) => <TextField {...props} fullWidth />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFormData((prev) => ({ ...prev, booked_on: moment(date).format("YYYY-MM-DD") }));
              }}
              // label="Check-in"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="booked_through">
            Booked Through
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="booked_through"
            value={formData?.booked_through}
            fullWidth
            id="booked_through"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="local_paid_via">
            Paid VIA
          </label>
          <Autocomplete
            value={formData?.local_paid_via}
            disablePortal
            options={[
              { label: "Stripe" },
              { label: "PayBy" },
              { label: "Tabby" },
              { label: "Bank Transfer" },
              { label: "Other" },
            ]}
            fullWidth
            onChange={(e, val) =>
              setFormData((prev) => ({
                ...prev,
                local_paid_via: val?.label,
                stripe_order_id: null,
                tabby_order_id: null,
                payby_order_number: null,
                payby_merchant_order_number: null,
              }))
            }
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        {formData?.local_paid_via === "Stripe" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="stripe_order_id">
              Stripe Payment ID
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="stripe_order_id"
              value={formData?.stripe_order_id}
              fullWidth
              id="stripe_order_id"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "Other" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="other_payment_method">
              Other
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="other_payment_method"
              value={formData?.other_payment_method}
              fullWidth
              id="other_payment_method"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "Tabby" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="tabby_order_id">
              Tabby Payment ID
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="tabby_order_id"
              value={formData?.tabby_order_id}
              fullWidth
              id="tabby_order_id"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "Bank Transfer" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="bank_refrence_id">
              Transfer ID
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="bank_refrence_id"
              value={formData?.bank_refrence_id}
              fullWidth
              id="bank_refrence_id"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "PayBy" && (
          <>
            <Grid item xs={12} sm={6}>
              <label style={{ fontSize: "14px" }} htmlFor="payby_order_number">
                PayBy Order Number
              </label>
              <TextField
                onChange={handleChangeFormDataField}
                name="payby_order_number"
                value={formData?.payby_order_number}
                fullWidth
                id="payby_order_number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label style={{ fontSize: "14px" }} htmlFor="payby_merchant_order_number">
                PayBy Merchant Order Number
              </label>
              <TextField
                onChange={handleChangeFormDataField}
                name="payby_merchant_order_number"
                value={formData?.payby_merchant_order_number}
                fullWidth
                id="payby_merchant_order_number"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="notes">
            Notes
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="notes"
            multiline
            rows={3}
            value={formData?.notes}
            fullWidth
            id="notes"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const FormThirdSection = ({ formData, handleChangeFormDataField, setFormData }) => {
  return (
    <Box>
      <Typography sx={{ mt: 2, mb: 1 }}>Cancellation</Typography>
      {/* <Typography variant="h5">First Section</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="cancelled_reason">
            Reason For Cancellation
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="cancelled_reason"
            value={formData?.cancelled_reason}
            fullWidth
            id="cancelled_reason"
          />
        </Grid>
        {formData?.cancelled_at && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="cancelled_at">
              Cancellation Date
            </label>
            <TextField
              onChange={handleChangeFormDataField} // This is just for testing
              name="cancelled_at"
              value={formData?.cancelled_at}
              fullWidth
              id="cancelled_at"
            />
          </Grid>
        )}
        {formData?.refunded_amount && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="refunded_amount">
              Refund Amount
            </label>
            <TextField
              onChange={handleChangeFormDataField} // This is just for testing
              name="refunded_amount"
              value={formData?.refunded_amount}
              fullWidth
              id="refunded_amount"
            />
          </Grid>
        )}
        {formData?.refund_date && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="refund_date">
              Refund Date
            </label>
            <TextField
              onChange={handleChangeFormDataField} // This is just for testing
              name="refund_date"
              value={formData?.refund_date}
              fullWidth
              id="refund_date"
            />
          </Grid>
        )}
        {!formData?.refund_date && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e, val) => setFormData((prev) => ({ ...prev, allow_refund: val }))}
                />
              }
              label="Refund Amount"
            />
          </Grid>
        )}
        {formData?.allow_refund && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="refunded_amount">
              Refunded Amount
            </label>
            <TextField
              onChange={handleChangeFormDataField} // This is just for testing
              name="refunded_amount"
              value={formData?.refunded_amount}
              fullWidth
              type="number"
              id="refunded_amount"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

function ManualVisaUpdate({ visa_data, hide_view, fetchVisaOrders }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [formData, setFormData] = React.useState({
    ...visa_data,
    local_paid_via: visa_data?.stripe_order_id
      ? "Stripe"
      : visa_data?.tabby_order_id
      ? "Tabby"
      : visa_data?.payby_order_number
      ? "PayBy"
      : visa_data?.bank_refrence_id
      ? "Bank Transfer"
      : "Other",
  });

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      console.log("isStepSkipped", activeStep);
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1 && formData?.cancelled_reason) {
      try {
        const { data } = await axiosInstance.post("/manual-visa/cancel", formData);

        if (formData?.allow_refund && !formData?.refund_date) {
          await axiosInstance.post("/manual-visa/refund", formData);
        }

        // console.log("data", data);
        hide_view();
        fetchVisaOrders();
        alert("Canceled Successfully");
      } catch (error) {
        console.log("error", error);
        alert("Canceled Failed");
      }
    } else if (
      activeStep === steps.length - 1 &&
      formData?.cancelled_reason &&
      formData?.allow_refund &&
      !formData?.refund_date
    ) {
      try {
        const { data } = await axiosInstance.post("/manual-visa/refund", formData);
        hide_view();
        fetchVisaOrders();
        alert("Refunded Successfully");
      } catch (error) {
        console.log("error", error);
        alert("Refund Failed");
      }
    } else if (activeStep === steps.length - 1) {
      handleUpdate();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleUpdate = async () => {
    try {
      const { data } = await axiosInstance.put("/manual-visa", formData);

      hide_view();
      alert("Updated Successfully");
    } catch (error) {
      console.log("error", error);
      alert("Error Updating");
    } finally {
      fetchVisaOrders();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChangeFormDataField = React.useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const handleChangeFormDataPayment = React.useCallback((val) => {
    if (val === "Stripe") {
      setFormData((prev) => ({
        ...prev,
        local_paid_via: "Stripe",
        tabby_order_id: null,
        payby_order_number: null,
        payby_merchant_order_number: null,
      }));
    } else if (val === "Tabby") {
      setFormData((prev) => ({
        ...prev,
        local_paid_via: "Tabby",
        stripe_order_id: null,
        payby_order_number: null,
        payby_merchant_order_number: null,
      }));
    } else if (val === "PayBy") {
      setFormData((prev) => ({
        ...prev,
        local_paid_via: "PayBy",
        stripe_order_id: null,
        tabby_order_id: null,
      }));
    }
  });

  const handleChangeFormDataFieldUknown = (name, val) => {
    setFormData((prev) => ({ ...prev, [name]: val }));
  };

  const steps = [
    {
      label: "Customer Details",
    },
    {
      label: "Visa Details",
    },
    {
      label: "Cancellation",
    },
  ];

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label?.label} {...stepProps}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {activeStep > index ? (
                  <Done fontSize="large" />
                ) : (
                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      background: activeStep >= index ? "#99f2c8" : "#FFF",
                      borderRadius: "50%",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {index + 1}
                  </Box>
                )}
              </Box>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <Box
          sx={{
            padding: "10px",
            my: 2,
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          {activeStep === 0 ? (
            <FormFirstSection
              formData={formData}
              handleChangeFormDataField={handleChangeFormDataField}
              setFormData={setFormData}
            />
          ) : activeStep === 1 ? (
            <FormSecondSection
              formData={formData}
              handleChangeFormDataField={handleChangeFormDataField}
              setFormData={setFormData}
              handleChangeFormDataPayment={handleChangeFormDataPayment}
            />
          ) : (
            <FormThirdSection
              formData={formData}
              handleChangeFormDataField={handleChangeFormDataField}
              setFormData={setFormData}
            />
          )}
          <Box
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 2 }}
          >
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>

            {activeStep === steps?.length - 2 ? (
              <Button onClick={handleUpdate}>Update</Button>
            ) : (
              <Box sx={{ flex: "1 1 auto" }} />
            )}

            <Button
              onClick={handleNext}
              variant="text"
              color={activeStep === steps.length - 1 ? "secondary" : "warning"}
              sx={{ color: "white" }}
            >
              {activeStep === steps.length - 1
                ? `${formData?.allow_refund ? "Refund Order" : "Cancel Order"}`
                : "Next"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ManualVisaUpdate;
